import React from "react";
import traditionalMenuImage from "../../../areas/main/assets/images/resources/traditional-menu.jpg";
import Texts from "../../../utils/lang-utils";
import urls from "../../../areas/main/assets/urls";
const Optional = () => {
  return (
    <section className="cta-one">
      <div className="container">

        <div className="row justify-content-lg-end">
          <div className="col-lg-6 mobile-hide">
          <img src={traditionalMenuImage} alt="alter text" style={{
                  maxWidth: '100%',
                  borderRadius: 20,
                  boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
            }} />
          </div>
          <div className="col-lg-6">
            <div className="cta-one__content">
              <i className="cta-one__icon dimon-icon-data1"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.QRMenu.OptionalTitle}} />
              </div>
              <div className="cta-one__text">
                <p dangerouslySetInnerHTML={{ __html: Texts.QRMenu.OptionalSubtitle}}>
                </p>
              </div>
              <p>
                  <br/>
                  <i className="fa fa-check"></i> {Texts.QRMenu.OptionalBullet1}
                  <br/>
                  <i className="fa fa-check"></i> {Texts.QRMenu.OptionalBullet2}
                  <br/>
                  <br/>

                </p>

              <div className="cta-one__text">
                <p dangerouslySetInnerHTML={{ __html: Texts.QRMenu.OptionalFooter}}>
                </p>
                <br></br>
              </div>
              <a href={urls.onboarding} className="thm-btn">
                <span>{Texts.GetStarted}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Optional;
